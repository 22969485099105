import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/quicksand"; // Defaults to weight 400.
import "@fontsource/quicksand/300.css";
import "@fontsource/quicksand/500.css";
import "@fontsource/quicksand/700.css";
import "@fontsource/fredoka-one";
import '../styles/common.scss';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowDown, faStar, faThumbsUp, faChartLine, faTabletAlt, faTachometerAlt } from '@fortawesome/free-solid-svg-icons'
import { faStar as farStar, faSquare as farSquare, faCheckSquare as farCheckSquare } from '@fortawesome/free-regular-svg-icons'

library.add(faArrowDown, faStar, faThumbsUp, faChartLine, faTabletAlt, faTachometerAlt);
library.add(farStar, farSquare, farCheckSquare);

export default function Layout({ children }) {
  return (
    <div>
      {children}
    </div>
  )
}
